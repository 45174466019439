import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import useStructuredData from '@/shared/composables/useStructuredData';
export default defineComponent({
    name: 'NotFoundPage',
    setup(_, context) {
        const { root } = context;
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const structuredData = {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: '404 Page Not Found',
            url: `${SITE_URL}/not-found`,
            breadcrumb: {
                '@type': 'BreadcrumbList',
                itemListElement: [
                    {
                        '@type': 'ListItem',
                        position: 1,
                        name: 'Home',
                        item: SITE_URL
                    }
                ]
            }
        };
        onMounted(() => {
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'error_404',
                category: 'errors',
                action: 'error_404',
                label: 'error 404',
                value: document.location.href,
                noninteraction: true
            });
            useMeta({
                title: root.$i18n.t('meta.not_found.title_tag').toString(),
                url: `${SITE_URL}/not-found`,
                noIndex: true
            });
            injectStructuredData(structuredData);
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            ROUTE_LIST_NAMES
        };
    }
});
